//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: "notification",
  title: "Customer",
  props: {
    customer: {
      type: Object,
      required: true,
      default: function _default() {
        return {};
      }
    },
    pageLoading: {
      type: Boolean,
      default: true
    }
  }
};