import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.splice.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Dialog from "@/view/pages/partials/Dialog.vue";
import { PATCH } from "@/core/services/store/request.module";
export default {
  props: {
    dialog: {
      type: Boolean,
      required: true,
      default: false
    },
    equipment: {
      type: Array,
      default: function _default() {
        return new Array();
      }
    }
  },
  watch: {
    equipment: {
      deep: true,
      immediate: true,
      handler: function handler(param) {
        this.equipmentList = param;
      }
    }
  },
  data: function data() {
    return {
      pageLoading: false,
      equipmentList: [{
        text: null
      }]
    };
  },
  methods: {
    updateEquipmentLocation: function updateEquipmentLocation() {
      var _this = this;

      _this.pageLoading = true;

      _this.$store.dispatch(PATCH, {
        url: "setting/equipment-location",
        data: {
          equipment_location: _this.equipmentList
        }
      }).then(function () {
        _this.$emit("close-dialog", true);

        _this.$emit("get-equipment-location", true);
      }).catch(function (error) {
        _this.logError(error);
      }).finally(function () {
        _this.pageLoading = false;
      });
    },
    createEquipmentLocation: function createEquipmentLocation() {
      this.equipmentList.push({
        text: null
      });
    },
    deleteEquipmentLocation: function deleteEquipmentLocation(index) {
      if (this.equipmentList.length > 1) {
        this.equipmentList.splice(index, 1);
      }
    }
  },
  components: {
    Dialog: Dialog
  },
  computed: {
    dialogWidth: function dialogWidth() {
      return this.lodash.toSafeInteger(document.body.clientWidth / 100 * 50);
    }
  }
};