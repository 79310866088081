import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.array.map.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import CommonMixin from "@/core/plugins/common-mixin";
import Barcode from "@/view/pages/partials/Barcode.vue";
export default {
  name: "billing-address",
  title: "Customer",
  mixins: [CommonMixin],
  data: function data() {
    return {
      billingAddress: []
    };
  },
  props: {
    customer: {
      type: Object,
      required: true,
      default: function _default() {
        return {};
      }
    },
    pageLoading: {
      type: Boolean,
      default: true
    }
  },
  components: {
    Barcode: Barcode
  },
  watch: {
    customer: function customer() {
      this.assignData();
    }
  },
  methods: {
    getAddress: function getAddress(address) {
      var addressArr = [];

      if (address.unit) {
        addressArr.push(address.unit);
      }

      if (address.street_1) {
        addressArr.push(address.street_1);
      }

      if (address.street_2) {
        addressArr.push(address.street_2);
      }

      if (address.zip_code) {
        addressArr.push(address.zip_code);
      }

      if (this.lodash.isEmpty(address.city) === false) {
        addressArr.push(address.city.name);
      }

      if (this.lodash.isEmpty(address.state) === false) {
        addressArr.push(address.state.name);
      }

      if (this.lodash.isEmpty(address.country) === false) {
        addressArr.push(address.country.name);
      }

      return addressArr.join(", ");
    },
    assignData: function assignData() {
      var _this = this;

      if (_this.lodash.isEmpty(_this.customer.address) === false) {
        var customerAddress = _this.lodash.map(_this.customer.address, function (data) {
          if (data.type === 2) return data;
        });

        _this.billingAddress = _this.lodash.without(customerAddress, undefined);
      }
    }
  },
  mounted: function mounted() {
    this.assignData();
  }
};