import "core-js/modules/es.object.to-string.js";
import { PATCH } from "@/core/services/store/request.module";
export default {
  methods: {
    patchCustomer: function patchCustomer(customer, data) {
      var _this = this;

      return new Promise(function (resolve, reject) {
        if (customer) {
          _this.$store.dispatch(PATCH, {
            url: "customer/" + customer,
            data: data
          }).then(function (_ref) {
            var data = _ref.data;
            resolve(data);
          }).catch(function (error) {
            resolve(error);
          });
        } else {
          reject("Customer is required");
        }
      });
    },
    patchCustomerContactPerson: function patchCustomerContactPerson(customer, persons, data) {
      var _this = this;

      return new Promise(function (resolve, reject) {
        if (customer) {
          _this.$store.dispatch(PATCH, {
            url: "customer/" + customer + "/contact-person/" + persons,
            data: data
          }).then(function (_ref2) {
            var data = _ref2.data;
            resolve(data);
          }).catch(function (error) {
            resolve(error);
          });
        } else {
          reject("Customer is required");
        }
      });
    },
    patchCustomerProperty: function patchCustomerProperty(customer, properties, data) {
      var _this = this;

      return new Promise(function (resolve, reject) {
        if (customer) {
          _this.$store.dispatch(PATCH, {
            url: "customer/" + customer + "/property-address/" + properties,
            data: data
          }).then(function (_ref3) {
            var data = _ref3.data;
            resolve(data);
          }).catch(function (error) {
            resolve(error);
          });
        } else {
          reject("Customer is required");
        }
      });
    },
    patchCustomerBilling: function patchCustomerBilling(customer, data) {
      var _this = this;

      return new Promise(function (resolve, reject) {
        if (customer) {
          _this.$store.dispatch(PATCH, {
            url: "customer/" + customer + "/billing-address",
            data: data
          }).then(function (_ref4) {
            var data = _ref4.data;
            resolve(data);
          }).catch(function (error) {
            resolve(error);
          });
        } else {
          reject("Customer is required");
        }
      });
    }
  }
};