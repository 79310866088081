import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.search.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { QUERY } from "@/core/services/store/request.module";
import Barcode from "@/view/pages/partials/Barcode.vue";
import ListingTable from "@/view/pages/partials/Listing-Table.vue";
export default {
  data: function data() {
    return {
      search: null,
      searching: true,
      properties: []
    };
  },
  components: {
    Barcode: Barcode,
    ListingTable: ListingTable
  },
  props: {
    customer: {
      type: Number,
      default: 0
    }
  },
  watch: {
    customer: function customer() {
      this.getProperties();
    }
  },
  methods: {
    createProperty: function createProperty() {
      this.$router.push(this.getDefaultRoute("property.create", {
        query: {
          customer: this.customer
        }
      }));
    },
    getProperties: function getProperties() {
      var _this = this;

      if (this.customer) {
        this.searching = true;
        this.$store.dispatch(QUERY, {
          url: "property/customer/".concat(this.customer),
          data: {
            search: this.search,
            status: "all"
          }
        }).then(function (_ref) {
          var data = _ref.data;
          _this.properties = data.rows;
        }).catch(function (error) {
          _this.logError(error);
        }).finally(function () {
          _this.searching = false;
        });
      }
    }
  },
  mounted: function mounted() {
    this.getProperties();
  }
};