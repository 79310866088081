import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.array.map.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import CommonMixin from "@/core/plugins/common-mixin";
import Status from "@/view/pages/partials/Status.vue";
import Barcode from "@/view/pages/partials/Barcode.vue";
export default {
  name: "property-address",
  title: "Customer",
  mixins: [CommonMixin],
  data: function data() {
    return {
      rowData: [],
      selectedRows: []
    };
  },
  props: {
    customer: {
      type: Object,
      required: true,
      default: function _default() {
        return {};
      }
    },
    pageLoading: {
      type: Boolean,
      default: true
    }
  },
  components: {
    Status: Status,
    Barcode: Barcode
  },
  watch: {
    selectedRows: function selectedRows(param) {
      this.$emit("selectedRows", param);
    },
    customer: function customer() {
      this.assignData();
    }
  },
  methods: {
    selectProperty: function selectProperty(param) {
      this.$emit("selectProperty", param);
    },
    getAddress: function getAddress(address) {
      var addressArr = [];

      if (address.unit) {
        addressArr.push(address.unit);
      }

      if (address.street_1) {
        addressArr.push(address.street_1);
      }

      if (address.street_2) {
        addressArr.push(address.street_2);
      }

      if (address.zip_code) {
        addressArr.push(address.zip_code);
      }

      if (this.lodash.isEmpty(address.city) === false) {
        addressArr.push(address.city.name);
      }

      if (this.lodash.isEmpty(address.state) === false) {
        addressArr.push(address.state.name);
      }

      if (this.lodash.isEmpty(address.country) === false) {
        addressArr.push(address.country.name);
      }

      return addressArr.join(", ");
    },
    assignData: function assignData() {
      var _this = this;

      if (_this.lodash.isEmpty(_this.customer.address) === false) {
        var customerAddress = _this.lodash.map(_this.customer.address, function (data) {
          if (data.type === 1) return data;
        });

        _this.rowData = _this.lodash.without(customerAddress, undefined);
      }
    }
  },
  mounted: function mounted() {
    var _this = this;

    _this.assignData();

    _this.$root.$on("clearSelection", function () {
      _this.selectedRows = [];
    });
  },
  beforeDestroy: function beforeDestroy() {
    this.$root.$off("clearSelection");
  },
  computed: {
    selectedAll: {
      set: function set(param) {
        this.selectedRows = [];

        if (param && this.rowData.length) {
          for (var i = this.rowData.length - 1; i >= 0; i--) {
            this.selectedRows.push(this.rowData[i].id);
          }
        }
      },
      get: function get() {
        var totalItems = this.rowData.length;
        return totalItems > 0 ? this.selectedRows.length === totalItems : false;
      }
    }
  }
};