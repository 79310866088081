//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import CommonMixin from "@/core/plugins/common-mixin";
import Status from "@/view/pages/partials/Status.vue";
import Barcode from "@/view/pages/partials/Barcode.vue";
export default {
  name: "contact-details",
  title: "Customer",
  mixins: [CommonMixin],
  props: {
    customer: {
      type: Object,
      required: true,
      default: function _default() {
        return {};
      }
    },
    pageLoading: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    getDetailType: function getDetailType(param) {
      if (param === 1) {
        return "Email";
      }

      return "Phone";
    }
  },
  components: {
    Status: Status,
    Barcode: Barcode
  },
  computed: {
    customerActivated: function customerActivated() {
      var _this = this;

      if (_this.lodash.isEmpty(_this.customer) === false) {
        return _this.customer.activated;
      }

      return false;
    },
    fullname: function fullname() {
      var name = "";

      var _this = this;

      if (_this.customer && _this.lodash.isEmpty(_this.customer.persons) === false) {
        for (var i = 0; i < _this.customer.persons.length; i++) {
          if (_this.customer.persons[i].default) {
            name = _this.lodash.startCase(_this.customer.persons[i].title) + ". " + _this.customer.persons[i].first_name + " " + _this.customer.persons[i].last_name;
          }
        }
      }

      return name;
    },
    primaryEmail: function primaryEmail() {
      var email = "";

      var _this = this;

      if (_this.customer && _this.lodash.isEmpty(_this.customer.details) === false) {
        for (var i = 0; i < _this.customer.details.length; i++) {
          if (_this.customer.details[i].type === 1) {
            email = _this.customer.details[i].value;
            break;
          }
        }
      }

      return email;
    },
    primaryPhone: function primaryPhone() {
      var phone = "";

      var _this = this;

      if (_this.customer && _this.lodash.isEmpty(_this.customer.details) === false) {
        for (var i = 0; i < _this.customer.details.length; i++) {
          if (_this.customer.details[i].type === 2) {
            phone = _this.customer.details[i].value;
            break;
          }
        }
      }

      return phone;
    },
    getProfileImage: function getProfileImage() {
      if (!this.customer.profile_logo) {
        return null;
      }

      return this.customer.profile_logo.file.url;
    },
    getBarcode: function getBarcode() {
      if (!this.customer.barcode) {
        return "";
      }

      return this.customer.barcode;
    }
  }
};