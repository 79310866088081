import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.to-string.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Dialog from "@/view/pages/partials/Dialog.vue";
import { QUERY, POST } from "@/core/services/store/request.module";
import ValidationMixin from "@/core/plugins/validation-mixin";
import ListingTable from "@/view/pages/partials/Listing-Table.vue";
export default {
  mixins: [ValidationMixin],
  props: {
    customer: {
      type: Number,
      required: true,
      default: 0
    },
    dialog: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  data: function data() {
    return {
      formValid: true,
      pageLoading: true,
      formLoading: false,
      serviceFormList: [],
      serviceForms: [],
      customerServiceForms: []
    };
  },
  watch: {
    customer: function customer(param) {
      if (param > 0) {
        this.getServiceForms(param);
      }
    }
  },
  methods: {
    onSubmit: function onSubmit() {
      var _this = this;

      if (!_this.$refs.serviceForm.validate()) {
        return false;
      }

      _this.formLoading = true;

      _this.$store.dispatch(POST, {
        url: "customer/service-forms",
        data: {
          customer: _this.customer,
          service_form: _this.customerServiceForms
        }
      }).then(function (_ref) {
        var data = _ref.data;

        _this.$emit("close:dialog");

        _this.getServiceForms(_this.customer);

        _this.serviceForms = data.service_forms;
        _this.customerServiceForms = data.customer_service_forms;
        _this.serviceFormList = data.customer_service_forms;
      }).catch(function (error) {
        _this.logError(error);
      }).finally(function () {
        _this.formLoading = false;
      });
    },
    getServiceForms: function getServiceForms(customer) {
      var _this = this;

      _this.$store.dispatch(QUERY, {
        url: "customer/service-forms",
        data: {
          customer: customer
        }
      }).then(function (_ref2) {
        var data = _ref2.data;
        _this.serviceForms = data.service_forms;
        _this.customerServiceForms = data.customer_service_forms;
        _this.serviceFormList = data.customer_service_forms;
      }).catch(function (error) {
        _this.logError(error);
      }).finally(function () {
        _this.pageLoading = false;
      });
    }
  },
  components: {
    Dialog: Dialog,
    ListingTable: ListingTable
  },
  mounted: function mounted() {
    if (this.customer > 0) {
      this.getServiceForms(this.customer);
    }
  }
};